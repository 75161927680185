<template>
  <v-dialog
    v-model="isVisible"
    max-width="320"
    :persistent="isSaving"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        fab
        small
        class="ml-6"
        color="primary"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary white--text">Créer un cursus</v-card-title>

      <v-card-text>
        <v-form ref="cursusForm">
          <v-select
            v-model="department"
            @change="checkEstPresenceInDept"
            label="Département"
            :items="deptList"
            :rules="[rules.required]"
          ></v-select>

          <v-select
            v-model="establishment"
            label="Implantation"
            :items="estList"
            :rules="[rules.required]"
            no-data-text="Sélectionnez un département"
          ></v-select>

          <v-text-field
            v-model="name"
            label="Nom"
            :rules="[rules.required, rules.name]"
          ></v-text-field>

          <v-text-field
            v-if="instanceType === 'DAHU'"
            v-model="quota"
            label="Quota"
            :rules="[rules.required, rules.quota]"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          @click="isVisible = false"
          text
          :disabled="isSaving"
        >
          Annuler
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          @click="saveCursus"
          text
          color="primary"
          :loading="isSaving"
        >
          Créer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'SaveCursusDialog',

  props: {
    instanceId: String,
    instanceType: String,
  },

  data: () => ({
    isVisible: false,
    isSaving: false,
    estByDept: {
      ABC: ['Ath'],
      ART: ['Saint-Ghislain'],
      ECO: ['Mons', 'Charleroi', 'Tournai'],
      LOG: ['Saint-Ghislain'],
      MGT: ['Mons', 'Saint-Ghislain'],
      MOT: ['Tournai', 'Charleroi'],
      PAR: ['Tournai', 'Mons', 'Saint-Ghislain', 'Charleroi'],
      PED: ['Charleroi', 'Mons', 'Morlanwelz'],
      SOC: ['Charleroi'],
      TEC: ['Ath', 'Tournai', 'Charleroi'],
    },
    department: null,
    establishment: null,
    name: '',
    quota: '',
    rules: {
      required: (v) => !!v || 'Ce champ est obligatoire',
      name: (v) => (v && v.length <= 150) || 'Le nom ne peut dépasser 150 caractères',
      quota: (v) => (v && /^(0|[1-9][0-9]{0,2})$/.test(v)) || 'Le quota doit être compris entre 0 et 999',
    },
  }),

  computed: {
    deptList() {
      return Object.keys(this.estByDept);
    },

    estList() {
      return this.estByDept[this.department];
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    checkEstPresenceInDept() {
      if (this.establishment && !this.estList.includes(this.establishment)) {
        this.establishment = null;
      }
    },

    async saveCursus() {
      if (!this.$refs.cursusForm.validate()) return;
      await this.$store.dispatch('getAccessToken');
      let notificationText;
      let notificationColor;
      try {
        this.isSaving = true;
        const response = await this.$http.post('admin/cursus-save.php', {
          instance_id: parseInt(this.instanceId, 10),
          dept_acronym: this.department,
          est_name: this.establishment,
          name: this.name,
          quota: this.quota,
        });
        this.$emit('save-cursus', response.data.cursus);
        notificationText = response.data.message;
        notificationColor = 'success';
        this.isVisible = false;
      } catch (error) {
        notificationText = error.response.data;
        notificationColor = 'error';
      } finally {
        this.isSaving = false;
        this.showNotification({
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
      }
    },
  },

  watch: {
    isVisible(val) {
      if (!val) {
        this.$refs.cursusForm.reset();
      }
    },
  },
};
</script>
