<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
    :persistent="isImporting"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        fab
        color="secondary"
        small
        class="ml-6"
      >
        <v-icon>mdi-database-search</v-icon>
      </v-btn>
    </template>

    <v-card tile>
      <v-toolbar
        dark
        color="primary"
        flat
      >
        <v-btn
          icon
          @click="isVisible = false"
          :disabled="isImporting"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>Importer des cursus</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn
            @click="openAllGroups"
            text
          >
            Ouvrir
          </v-btn>

          <v-btn
            @click="closeAllGroups"
            text
          >
            Fermer
          </v-btn>

          <v-btn
            @click="importCursus"
            text
            :loading="isImporting"
          >
            Importer
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-divider></v-divider>

      <v-data-table
        v-model="selectedCursus"
        :headers="headers"
        :items="availableCursus"
        sort-by="name"
        group-by="dept_acronym"
        disable-pagination
        hide-default-footer
        show-select
      >
        <template v-slot:group.header="{ headers, group, isOpen, toggle }">
          <td
            :colspan="headers.length"
            class="dept-header"
            @click="toggle"
            :ref="group"
            :data-open="isOpen"
          >
            <div class="d-flex align-center">
              <v-btn icon>
                <v-icon v-if="!isOpen">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-up</v-icon>
              </v-btn>

              <v-icon class="mx-6">{{ getDeptIcon(group) }}</v-icon>

              <span class="font-weight-bold">{{ group }}</span>

              <v-spacer></v-spacer>

              <span class="text--secondary ml-6">
                ({{ selectedCursusNumberByDept[group] || '0' }} /
                {{ cursusNumberByDept[group] }})
              </span>
            </div>
          </td>
        </template>

        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            v-if="!importedCursus.some((cursus) => cursus.id_gestac == item.id)"
            :ripple="false"
            @input="select(!isSelected)"
            :value="isSelected"
          ></v-simple-checkbox>

          <v-icon v-else color="success">mdi-check</v-icon>
        </template>

        <template v-slot:item.est_name="{ item }">
          <v-chip small label>{{ item.est_name.toUpperCase() }}</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import { getDeptIcon } from '@/mixins/methodsMixin';

export default {
  name: 'ImportCursusDialog',

  props: {
    importedCursus: Array,
    instanceId: String,
  },

  mixins: [getDeptIcon],

  data: () => ({
    isImporting: false,
    isVisible: false,
    headers: [
      { text: 'CURSUS', value: 'name' },
      { text: 'IMPLANTATION', value: 'est_name' },
    ],
    cursus: [],
    selectedCursus: [],
  }),

  created() {
    this.getCursus();
  },

  computed: {
    availableCursus() {
      return this.cursus.map((cursus) => {
        const availCursus = { ...cursus };
        // eslint-disable-next-line eqeqeq
        if (this.importedCursus.some((importedCursus) => importedCursus.id_gestac == cursus.id)) {
          availCursus.isSelectable = false;
        }
        return availCursus;
      });
    },

    cursusNumberByDept() {
      const counter = {};
      this.cursus.forEach((cursus) => {
        if (Object.keys(counter).includes(cursus.dept_acronym)) {
          counter[cursus.dept_acronym] += 1;
        } else {
          counter[cursus.dept_acronym] = 1;
        }
      });
      return counter;
    },

    selectedCursusNumberByDept() {
      const counter = {};
      this.selectedCursus.forEach((cursus) => {
        if (Object.keys(counter).includes(cursus.dept_acronym)) {
          counter[cursus.dept_acronym] += 1;
        } else {
          counter[cursus.dept_acronym] = 1;
        }
      });
      return counter;
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    async importCursus() {
      await this.$store.dispatch('getAccessToken');
      let notificationText;
      let notificationColor;
      try {
        this.isImporting = true;
        const response = await this.$http.post('admin/cursus-import.php', {
          instance_id: parseInt(this.instanceId, 10),
          cursus: this.selectedCursus,
        });
        this.$emit('import-cursus');
        notificationText = response.data;
        notificationColor = 'success';
        this.isVisible = false;
      } catch (error) {
        notificationText = error.response.data;
        notificationColor = 'error';
      } finally {
        this.isImporting = false;
        this.showNotification({
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
      }
    },

    closeAllGroups() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && this.$refs[k].getAttribute('data-open')) {
          this.$refs[k].click();
        }
      });
    },

    openAllGroups() {
      Object.keys(this.$refs).forEach((k) => {
        if (this.$refs[k] && !this.$refs[k].getAttribute('data-open')) {
          this.$refs[k].click();
        }
      });
    },

    async getCursus() {
      await this.$store.dispatch('getAccessToken');
      try {
        const response = await this.$http.get(`admin/misc-get-gestac-cursus.php?instance_id=${this.instanceId}`);
        this.cursus = response.data;
      } catch (error) {
        this.showNotification({
          isVisible: true,
          text: error.response.data,
          color: 'error',
        });
      }
    },
  },

  watch: {
    isVisible(val) {
      if (!val) {
        this.selectedCursus = [];
      }
    },
  },
};
</script>

<style scoped>

.dept-header {
  cursor: pointer;
}

</style>
