<template>
  <v-dialog
    v-model="isVisible"
    max-width="600"
    :persistent="isDeleting"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        fab
        small
        color="error"
        class="ml-6"
        :disabled="cursus.length === 0"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="error white--text">Supprimer des cursus</v-card-title>

      <v-card-text class="pt-5">
        <div>
          Êtes-vous sûr de vouloir supprimer ces cursus ?
        </div>
        <div class="mt-2">Toutes les données qui leur sont attachées seront perdues.</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-virtual-scroll
        :items="cursus"
        item-height="62"
        :height="scrollHeight"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>{{ getDeptIcon(item.dept_acronym) }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>

              <v-list-item-subtitle>{{ item.est_name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-virtual-scroll>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          @click="isVisible = false"
          text
          :disabled="isDeleting"
        >
          Annuler
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          @click="deleteCursus"
          text
          color="error"
          :loading="isDeleting"
        >
          Supprimer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';
import { getDeptIcon } from '@/mixins/methodsMixin';

export default {
  name: 'DeleteCursusDialog',

  props: {
    cursus: Array,
    instanceId: String,
  },

  mixins: [getDeptIcon],

  data: () => ({
    isVisible: false,
    isDeleting: false,
  }),

  computed: {
    scrollHeight() {
      const cursusLength = this.cursus.length;
      if (cursusLength >= 5) {
        return 310;
      }
      return cursusLength * 62;
    },
  },

  methods: {
    ...mapMutations({ showNotification: 'SET_NOTIFICATION' }),

    async deleteCursus() {
      await this.$store.dispatch('getAccessToken');
      let notificationText;
      let notificationColor;
      try {
        this.isDeleting = true;
        const response = await this.$http.post('admin/cursus-delete.php', {
          instance_id: parseInt(this.instanceId, 10),
          cursus_ids: this.cursus.map((cursus) => cursus.id),
        });
        this.$emit('delete-cursus');
        notificationText = response.data;
        notificationColor = 'success';
        this.isVisible = false;
      } catch (error) {
        notificationText = error.response.data;
        notificationColor = 'error';
      } finally {
        this.isDeleting = false;
        this.showNotification({
          isVisible: true,
          text: notificationText,
          color: notificationColor,
        });
      }
    },
  },
};
</script>
